import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Footer from './Footer'
import img from './img/APKtFqvr.jpeg';
import logo from './img/stuttgart.png';

class Ds1Detail extends React.Component {
  render() {
    return (
        <div>
            <Container fluid={true}>
            <hr/>
            <h3>IEEE CTW 2019 Challenge</h3>
            <br/>
            1.25GHz Localiation Dataset provided by University of Stuttgart
            <hr />
            <Row>
                <Col xs="6">
                    <img src={img} height="300" width="500" />
                    <br/>
                    <br/>
                    <b>Background</b>
                    <br/>
                    Indoor positioning is a key enabler for a wide range of applications, including navigation, smart factories and cities, surveillance, security, IoT, and sensor networks.  Additionally, indoor position can be leveraged for improved beamforming and channel estimation in wireless communications.
                    <br/>
                    <br/>
                    <b>Objective</b>
                    <br/>
                    The object of the competition is to design and train and algorithm that can determine the position of a user, based on estimated channel frequency responses between the user and an antenna array.  Possible solutions may build on classic algorithms (fingerprinting, interpoliation) or machine-learning approaches.
                    <br/>
                    <br/>
                    <b>Additional Details</b>
                    <br/>
                    Additional details on the dataset and competition can be found <a href="https://github.com/MaximilianArnold/CTW2019-PositioningCompetition/blob/master/0_CompetitionDescription/CTW2019_UserPos_Comp.pdf">here</a>
                    <br/>
                    Competition presentations will take place at <a href="http://ctw2019.ieee-ctw.org/">IEEE CTW 2019</a>
                </Col>
                <Col xs="4">
                    <b>Origin</b>
                    <br/>
                    <a href="http://www.inue.uni-stuttgart.de/institut/institut.en.html"> <img src={logo}/> <br/>
                    Institut für Nachrichtenübertragung, <br/>
                    University of Stuttgart
                    </a>
                    <br/>
                    <br/>
                    <b>License</b>
                    <br/>
                    <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0</a>
                    <br/>
                    <br/>
                    <b>Resources</b>
                    <br/>
                    <a href="https://github.com/MaximilianArnold/CTW2019-PositioningCompetition/blob/master/CTW2019_StartingNotebook.ipynb">Example Notebook</a>
                    <br/>
                    <a href="https://github.com/MaximilianArnold/CTW2019-PositioningCompetition">GitHub Repository</a>
                    <br/>
                    <br/>
                    <b>Dataset Files</b>
                    <br/>
                    HDF5: <a href="/dist/01/CTW2019_Dataset_h5.zip">CTW2019_Dataset_h5.zip</a> (3.7GB)
                    <br/>
                    Mat: <a href="/dist/01/CTW2019_Dataset_HDF5_Mat.zip">CTW2019_Dataset_HDF5_Mat.zip</a> (3.8GB)
                    <br/>
                    Pkl: <a href="/dist/01/CTW2019_Dataset_Pickel.zip">CTW2019_Dataset_Pickel.zip</a> (3.7GB)
                    <br/>
                    <br/>
                    <b>References</b>
                    <br/>
                    <a href="https://arxiv.org/abs/1810.04126">Novel Massive MIMO Channel Sounding Data Applied to Deep Learning-based Indoor Positioning</a>
                </Col>
            </Row>
            </Container>
            <Footer />
        </div>
    )
  }
}

export default Ds1Detail;
