import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Footer from './Footer'
import img from './img/Urban.png';
import logo from './img/stuttgart.png';

class Ds4Detail extends React.Component {
  render() {
    return (
        <div>
            <Container fluid={true}>
            <hr/>
            <h3>IEEE CTW 2020 Challenge</h3>
            <br/>
            Suburban 1.25GHz Localiation Dataset provided by University of Stuttgart
            <hr />
            <Row>
                <Col xs="6">
                    <img src={img} height="300" width="500" />
                    <br/>
                    <br/>
                    <b>Background</b>
                    <br/>
                    Indoor positioning is a key enabler for a wide range of applications, including navigation, smart factories and cities, surveillance, security, IoT, and sensor networks.  Additionally, indoor position can be leveraged for improved beamforming and channel estimation in wireless communications.
                    <br/>
                    <br/>
                    <b>Objective</b>
                    <br/>
                    The object of the competition is to design and train and algorithm that can determine the position of a user, based on estimated channel impulse responses. Moreover improving accuracy by using a semi-supervised learning approach to re-use unlabeld data.
                    <br/>
                    <br/>
                    <b>Additional Details</b>
                    <br/>
                    <br/>
                    Competition presentations will take place at <a href="http://ctw2020.ieee-ctw.org/">IEEE CTW 2020</a>
                </Col>
                <Col xs="4">
                    <b>Origin</b>
                    <br/>
                    <a href="http://www.inue.uni-stuttgart.de/institut/institut.en.html"> <img src={logo}/> <br/>
                    Institut für Nachrichtenübertragung, <br/>
                    University of Stuttgart
                    </a>
                    <br/>
                    <br/>
                    <b>License</b>
                    <br/>
                    <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0</a>
                    <br/>
                    <br/>
                    <b>Resources</b>
                    <br/>
                    <br/>
                    <b>Dataset Files</b>
                    <br/>
                    Labeled  HDF5: <a href="/dist/CTW2020/CTW2020_labelled_data.zip">CTW2020_labelled_data.zip</a> (3GB)
                    <br/>
                    Unlabeled  HDF5: <a href="/dist/CTW2020/CTW2020_unlabelled_data.zip">CTW2020_unlabelled_data.zip</a> (22.3GB)
                    <br/>
                    <br/>
                    Sample Jupyter Notebook: <a href="/dist/CTW2020/Example_notebook_to_load_data.ipynb">Example_notebook_to_load_data.ipynb</a> (200KB)
                    <br/>
                    <b>References</b>
                    <br/>
                    <a href="https://arxiv.org/abs/2002.09452">Massive MIMO Channel Measurements and Achievable Rates in a Residential Area</a>
                </Col>
            </Row>
            </Container>
            <Footer />
        </div>
    )
  }
}

export default Ds4Detail;
