import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Footer from './Footer'
import img from './img/ata-seti.jpg';
import logo from './img/berkeleysetithumb.png';

class Ds3Detail extends React.Component {
  render() {
    return (
        <div>
            <Container fluid={true}>
            <hr/>
            <h3>Space Observation Data</h3>
            <br/>
            Allen Telescope Array Observation Data provided by the Berkeley SETI Research Center    
            <hr />
            <Row>
                <Col xs="6">
                    <img src={img} height="250" width="400" />
                    <br/>
                    <br/>
                    <b>Background</b>
                    <br/>
                    Numerous astrological object emit interesting emissions at different bands in the RF spectrum.   To facilitate observations of these traces, we've provided a handful of RF observation recordings from the GNU Radio/ATA hackfest online for others to inspect or to conduct their own experiments on here.
                    <br/>
                    <br/>
                    <b>Objective</b>
                    <br/>
                    A variety of observations are provided here with which a range of different signal processing objectives or observation or measurement experiments could be performed.           
                    <br/>
                    <br/>
                    <b>Additional Details</b>
                    <br/>
                    Additional details will be provided here as they are available.
                    <br/>
                </Col>
                <Col xs="4">
                    <b>Origin</b>
                    <br/>
                    <a href="https://seti.berkeley.edu/"> <img src={logo} height="100" width="100"/> <br/>
                    Allen Telescope Array & Berkeley SETI Research Center 
                    </a>
                    <br/>
                    <br/>
                    <b>License</b>
                    <br/>
                    <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0</a>
                    <br/>
                    <br/>
                    <b>Resources</b>
                    <br/>
                    We would love to provide some additional resources here, please send your experimental notebooks and works with these so we can link them as great examples and tutorials!
                    <br/>
                    <br/>
                    <b>Dataset Files</b>
                    <br/>
                    Raw: <a href="/dist/03/CassA_1420MHz_2.5MSPS.correctspectrum.iq">CassA_1420MHz_2.5MSPS.correctspectrum.iq</a> (656 MB)
                    <br/>
                    Raw: <a href="/dist/03/w3oh_1665MHz_2.5MSPS.correctspectrum.iq">w3oh_1665MHz_2.5MSPS.correctspectrum.iq</a> (814 MB)
                    <br/>
                    <br/>
                    <b>References</b>
                    <br/>
                    <a href="https://en.wikipedia.org/wiki/Cassiopeia_A">Cassiopeia A</a>
                    <br/>
                    <a href="http://simbad.u-strasbg.fr/simbad/sim-id?Ident=W3(OH)">WS(OH) Obsservations</a>
                </Col>
            </Row>
            </Container>
            <Footer />
        </div>
    )
  }
}

export default Ds3Detail;
