// https://m.pardel.net/react-and-bootstrap-4-part-1-setup-navigation-d4767e2ed9f0
import React from 'react';
import { View, Image} from 'react-native';

class Footer extends React.Component {
  render() {
    return (
        <div>
            <br/>
            <hr/>
            This dataset server is hosted by Virginia Tech in Arlington, VA.  Datasets remain the property of their originators.
            <hr/>
        </div>
    )
  }
}

export default Footer;
