import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { NavLink, Switch, Route } from 'react-router-dom';

import logo from './img/Urban.png';

class Ds4 extends React.Component {
  render() {
    return (
        <Card>
            <CardImg top width="100%" src={logo} alt="Card image cap" />
                <CardBody>
                    <CardTitle>IEEE CTW 2020 Challenge</CardTitle>
                    <CardSubtitle>Suburban 1.25GHz Localization Dataset</CardSubtitle>
                    <CardText>Consisting of 64 element OFDM sounding data provided by the Institute of Communications (INUE), University of Stuttgart with ground truth location data.</CardText>
                    <NavLink exact activeClassName="current" to='/Ds4Detail'>Dataset Details</NavLink>
            </CardBody>
        </Card>
    )
  }
}

export default Ds4;
