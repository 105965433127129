import React, { Component } from 'react';
import './App.css';
import Navigation from './components/Navigation';
import Main from './components/Main';
import ds1 from './components/ds1';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';

import Ds1Detail from './components/ds1detail'
import Ds2Detail from './components/ds2detail'
import Ds3Detail from './components/ds3detail'
import Ds4Detail from './components/ds4detail'

const navbar = {backgroundColor: '#3782af', color: '#FFFFFF', width:'100%', padding:'20px 20px 20px 20px'};

class App extends Component {
  componentDidMount(){
          document.title = "IEEE MLC Datasets Server @ VT.edu"
        }

    render() {
    return (
        <div>
            <div style={{height: '20px', backgroundColor: '#c0c0c0'}}> </div>
            <div style={navbar}>
                <Navigation />
                <h3> Machine Learning for Communications Emerging Technologies Initiative</h3>
            </div>
            <Container className="App">
                <Router>
                    <div>
                        <Route exact path="/" component={Home} />
                        <Route path="/Ds1Detail" component={Ds1Detail} />
                        <Route path="/Ds2Detail" component={Ds2Detail} />
                        <Route path="/Ds3Detail" component={Ds3Detail} />
			<Route path="/Ds4Detail" component={Ds4Detail} />
                    </div>
                </Router>
            </Container>
        </div>
    );
  }
}

function Home() {
    return (
        <div>
            <Main />
        </div>
    );
}
function Home2() {
    return (
        <div>
            WAT
        </div>
    );
}

export default App;
