import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import Footer from './Footer'
import img from './img/mmimoimg.png';
import logo from './img/cheru.gif';

class Ds2Detail extends React.Component {
  render() {
    return (
        <div>
            <Container fluid={true}>
            <hr/>
            <h3>Power Allocation in Multi-Cell Massive MIMO</h3>
            <br/>
            Simulated Dataset provided by University of Pisa
            <hr />
            <Row>
                <Col xs="6">
                    <img src={img} height="300" width="500" />
                    <br/>
                    <br/>
                    <b>Background</b>
                    <br/>
                    Massive MIMO is a key enabler for 5G and for future dense many-user many-cell networks.   Optimal design, deployment and algorithm selection for BTS and UE devices is key to efficiently using spectrum resources and maximizing the performance metrics of such systems.
                    <br/>
                    <br/>
                    <b>Objective</b>
                    <br/>
                    This dataset contains data samples that are needed to train neural networks to perform max-min and max-prod power allocation in the downlink of the Massive MIMO network considered in <a href="https://arxiv.org/abs/1812.03640">https://arxiv.org/abs/1812.03640</a>.
                    <br/>
                    <br/>
                    <b>Additional Details</b>
                    <br/>
                    The MATLAB code package used to generate further data samples is available at https://github.com/lucasanguinetti/ and is the same used to produce Figure 7.2 in the monograph: <br/>
                    <br/>
Emil Bjornson, Jakob Hoydis and Luca Sanguinetti (2017), "Massive MIMO Networks: Spectral, Energy, and Hardware Efficiency", Foundations and Trends in Signal Processing: Vol. 11, No. 3-4, pp. 154-655. <br/>
                    <br/>
You can download a free PDF of the manuscript at <a href="https://massivemimobook.com">https://massivemimobook.com</a> <br/>
                    <br/>
                </Col>
                <Col xs="4">
                    <b>Origin</b>
                    <br/>
                    <a href="http://www.iet.unipi.it/l.sanguinett"> <img src={logo}/> <br/>
                    Dipartimento di Ingegneria dell'Informazione, <br/>
                    University of Pisa
                    </a>
                    <br/>
                    <br/>
                    <b>License</b>
                    <br/>
                    Public Domain
                    <br/>
                    <br/>
                    <b>Resources</b>
                    <br/>
                    Matlab included in zip file <br/>
                    <a href="https://massivemimobook.com/wp/">Massive MIMO Networks</a>  <br/>
                    <a href="https://github.com/emilbjornson/massivemimobook">Simulation code on GitHub</a> <br/>
                    <br/>
                    <br/>
                    <b>Dataset Files</b>
                    <br/>
                    Mat: <a href="/dist/02/multi_cell.zip">multi_cell.zip</a> (5.3GB)
                    <br/>
                    <br/>
                    <b>References</b>
                    <br/>
                    <a href="https://massivemimobook.com">Massive MIMO Networks: Spectral, Energy, and Hardware Efficiency</a> <br/>
                    <a href="https://arxiv.org/abs/1812.03640">Deep Learning Power Allocation in Massive MIMO</a> <br/>
                </Col>
            </Row>
            </Container>
            <Footer />
        </div>
    )
  }
}

export default Ds2Detail;
