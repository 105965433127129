import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { NavLink, Switch, Route } from 'react-router-dom';

import logo from './img/mmimoimg.png';

class Ds2 extends React.Component {
  render() {
    return (
            <Card>
                <CardImg top width="100%" src={logo} alt="Power Allocation in Multi-Cell Massive MIMO" />
                <CardBody>
                  <CardTitle>Power Allocation in Multi-Cell Massive MIMO</CardTitle>
                  <CardSubtitle></CardSubtitle>
                  <CardText>Dataset for max-min and max-prod power allocation in a four-cell Massive MIMO network with five user terminals per cell, provided by Dipartimento di Ingegneria dell'Informazione, University of Pisa. 
                  </CardText>
                  <NavLink exact activeClassName="current" to='/Ds2Detail'>Dataset Details</NavLink>
                </CardBody>
              </Card>
    )
  }
}

export default Ds2;
