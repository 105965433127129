// https://m.pardel.net/react-and-bootstrap-4-part-1-setup-navigation-d4767e2ed9f0
import React from 'react';
import { View, Image} from 'react-native';


class Navigation extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light" >
        
                <View>
                <Image
                    style={{width: 150, height: 60}}
                    source={require('../ieee_comsoc_new_pad.png')}
                />
                </View>
                

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="/">MLC Datasets<span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://mlc.committees.comsoc.org/">MLC ETI Home</a>
            </li>
          </ul>
          <View>
            <Image
              style={{width: 200, height: 60}}
              source={require('../ieee_mb_white@2x.png')}
            />
          </View>
        </div>
      </nav>
    )
//          <form className="form-inline my-2 my-lg-0">
//            <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
//            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
//          </form>
  }
}

export default Navigation;
