import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { NavLink, Switch, Route } from 'react-router-dom';

import logo from './img/ata-seti.jpg';

class Ds3 extends React.Component {
  render() {
    return (
            <Card>
                <CardImg top width="100%" src={logo} alt="Allen Telescope Array Observations" />
                <CardBody>
                  <CardTitle>Allen Telescope Array Obsevations</CardTitle>
                  <CardSubtitle></CardSubtitle>
                  <CardText>Datasets of various I/Q baseband recordings of natural phenomena observations on the Allen Telescope Array (ATA), provided by the SETI Institute. 
                  </CardText>
                  <NavLink exact activeClassName="current" to='/Ds3Detail'>Dataset Details</NavLink>
                </CardBody>
              </Card>
    )
  }
}

export default Ds3;
