import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button, CardImg, Grid, Row, Container, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';

import Ds1 from './ds1';
import Ds2 from './ds2';
import Ds3 from './ds3';
import Ds4 from './ds4';
import Footer from './Footer'

class Main extends React.Component {
  render() {
    return (
        <div>
            <hr/>
            <Row>
                <h3>Open Dataset Initiative - Contributed Datasets and Baselines</h3>
            </Row>
            <Row>
                These datasets have been generously contributed by several universities and organizations to help facilitate quantitative and rigorous comparison of algorithmic approaches to a range of communications system tasks using machine learning and non-machine learning based processing techniques.  New high quality contributions are strongly encouraged, please contact the IEEE MLC organizers over email or slack with your interest.  Please make a best effort to cite the data originators!
            </Row>
            <hr/>
            <Row>
                <Col sm="4"> <Ds1 /> </Col>
                <Col sm="4"> <Ds2 /> </Col>
                <Col sm="4"> <Ds3 /> </Col>
                <Col sm="4"> <Ds4 /> </Col> 
            </Row>
            <br/>
            <Footer />
        </div>
    )
  }
}

export default Main;
